<template>
  <div>   
    <v-container grid-list-xl> 
        <v-layout align-center="" justify-center="">
            <v-col sm="12" cols="12">
                <br>
                <v-card > 
                    <mbs-page-tab
                        :items="PageTaps" />  
                    <v-card v-if="!PAGE_LOAD" 
                        :height="vsh/2"
                        tile="">
                        <mbs-page-spinner />  
                    </v-card>
                    <div v-else class=""> 
                        <v-card tile flat>
                            <v-toolbar color="primary" flat>
                                <v-spacer></v-spacer>  
                                <v-btn
                                    class="mx-2" @click="ADD_ITEM()"  color="secondary" dark >
                                    <v-icon>add</v-icon>
                                    Add {{MBS.actions.TEXT_UP1(NAME_ITEM)}}
                                </v-btn>
                                <v-menu  bottom left 
                                    v-model="menuTableSet"
                                    :close-on-content-click="false"
                                    :nudge-width="250">
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="mr-5"
                                            dark outlined="" rounded=""
                                            icon
                                            v-on="on" >
                                            <v-icon>mdi-tune</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title primary-title>
                                            <v-layout >
                                                CUSTOMIZE TABLE
                                                <v-spacer></v-spacer>
                                                <v-btn icon="" @click="menuTableSet=false"><v-icon>close</v-icon></v-btn>
                                            </v-layout>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-list subheader="" dense="">
                                                <div v-for="(item, i) in HEADER_ITEMS" :key="i">
                                                    <v-subheader v-if="item.title">{{item.name}}</v-subheader>
                                                    <v-list-item v-else  @click="MBS.actions.TABLE_HEADER_SELECTOR(HEADER_ITEMS,i)" >
                                                        <v-list-item-icon><v-icon>{{item.show?"mdi-check-box-outline":"mdi-checkbox-blank-outline"}}</v-icon></v-list-item-icon>
                                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                                    </v-list-item> 
                                                </div> 
                                            </v-list> 
                                            <v-layout >
                                                <v-spacer></v-spacer>
                                                <v-btn @click="menuTableSet = false" color="primary">OK</v-btn>
                                            </v-layout>
                                        </v-card-text>
                                        
                                    </v-card> 
                                </v-menu>  
                            </v-toolbar>
                            <v-card-text  v-if="MBS.actions.SIZE(JOINED_DATA_ITEMS)>0">
                                <v-card-title  >
                                    {{MBS.actions.TEXT_UP1(NAME_ITEMS)}} 
                                    <v-spacer></v-spacer>
                                    
                                    <v-text-field
                                        v-model="search"
                                        append-icon="search"
                                        label="Search"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-card-title> 
                                <v-data-table   
                                    :headers="HEADERS(VALUE_ITEMS)"
                                    :items="JOINED_DATA_ITEMS"
                                    :search="search" >

                                    <template v-slot:top> 
                                    </template>  

                                    <template v-slot:item.action="{ item,index }" >  
                                        <v-menu 
                                            :set="localItem = item"
                                            tile
                                            v-model="item.menuAction" 
                                            :close-on-content-click="false" 
                                            bottom=""   
                                            eager
                                            :nudge-width="250">
                                            <template v-slot:activator="{ on }">   
                                                <v-btn
                                                    ref="btn"
                                                    id="btn"  
                                                    v-on="on"  
                                                    color="c5"
                                                    icon   >
                                                    <v-icon >mdi-dots-vertical</v-icon>
                                                </v-btn> 
                                            </template>  
                                            <v-card tile 
                                                color="lighten-1" class="">   
                                                <v-card-title  class="headline primary lighten-1 py-1"> 
                                                    <v-subheader class="white--text font-weight-bold">
                                                        {{item.no}}
                                                    </v-subheader>
                                                    <v-subheader class="white--text font-weight-bold">
                                                        {{MBS.actions.TEXT_UP11(item.name)}}
                                                    </v-subheader>
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="item.menuAction=false" dark="" icon=""><v-icon>close</v-icon></v-btn>
                                                </v-card-title> 
                                                <!-- <v-subheader class="b1">MY ACCOUNT</v-subheader> -->
                                                <v-virtual-scroll  
                                                    :bench="3"
                                                    :items="['']"
                                                    height="250"
                                                    item-height="50"  > 
                                                    <template  >  
                                                        <v-list-item  
                                                           @click="EDIT_ITEM(item)">
                                                            <v-list-item-action >
                                                                <v-btn 
                                                                    color="primary" 
                                                                    icon>
                                                                        <v-icon size="24px">edit</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                            <v-list-item-title>Edit {{NAME_ITEM}}</v-list-item-title>
                                                        </v-list-item> 
                                                        <v-divider ></v-divider>   
                                                        <div v-if="!item.admin">
                                                            <v-list-item  
                                                                @click="SHOW_CHILD_ITEM(item)"   >
                                                                <v-list-item-action>
                                                                    <v-btn 
                                                                        color="primary" 
                                                                        icon>
                                                                            <v-icon>check_box</v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                                <v-list-item-title>Manage Permission</v-list-item-title>
                                                            </v-list-item>  
                                                        </div> 
                                                        <v-divider ></v-divider>   
                                                        <div v-if="!item.admin">
                                                            <v-list-item  
                                                                @click="DELETE_ITEM(item)"   >
                                                                <v-list-item-action>
                                                                    <v-btn 
                                                                        color="primary" 
                                                                        icon>
                                                                            <v-icon>delete</v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                                <v-list-item-title>Delete {{NAME_ITEM}}</v-list-item-title>
                                                            </v-list-item>  
                                                        </div> 
                                                    </template> 
                                                </v-virtual-scroll> 
                                                
                                                <v-divider></v-divider>
                                                    
                                                <v-card-actions class="b1" v-if="false">
                                                    <v-layout class="pa-2  ">
                                                        <v-spacer></v-spacer>
                                                        <div class="mr-2">
                                                            
                                                        </div>
                                                        <v-btn 
                                                            :small = "true"
                                                            rounded outlined
                                                            color="primary"
                                                            @click="item.menuAction=false" >
                                                            <div class="px-2">Close</div> 
                                                        </v-btn> 
                                                    </v-layout> 
                                                </v-card-actions>
                                            </v-card>  
                                        </v-menu>  
                                    </template>
                                    <template v-slot:no-data >
                                        <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                            <v-icon>warning</v-icon>
                                            Empty
                                        </v-layout>
                                    </template>
                                </v-data-table>   
                            </v-card-text> 
                            <v-sheet v-else  class=""
                                :height="vsh/2"
                                tile="">
                                <v-layout justify-center="" align-center="" fill-height="">
                                    <v-icon class="px-1 pb-1">warning</v-icon>
                                    <div>No {{MBS.actions.TEXT_UP1(NAME_ITEM)}}  Found</div>
                                </v-layout>
                            </v-sheet> 
                        </v-card>
                    </div>
                </v-card>
            </v-col>  
        </v-layout>
    </v-container> 
    <br>

    <!-- //DIALOGS -->
    <div>
        <!-- add -->
        <v-dialog v-model="dialog_add_item" persistent max-width="800px"> 
            <v-card>
                <v-card-title class="primary white--text" dark>
                    <span class="headline"> ADD {{MBS.actions.TEXT_UP(CHILD?NAME_CHILD_ITEM:NAME_ITEM)}}</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isAutoCode(inputCode)" @click="setAutoCode()" color="white" outlined="" rounded="" class="ml-2">AUTO GENERATE CODE</v-btn>
                    <!-- <v-btn @click="SELECT_EXCEL()" color="white" outlined="" rounded="" class="ml-2"><v-icon size="" class="pr-1">mdi-file-excel-outline</v-icon> EXCEL</v-btn> -->
                    <v-btn class="ml-2" dark @click="dialog_add_item=false" icon=""><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text> 
                    <v-container grid-list-lg>
                        <br>
                        <v-card outlined class="px-5 py-4 mb-4" v-if="CHILD">
                            <v-layout >
                                <strong>{{MBS.actions.TEXT_UP('Selected item name: ')}}</strong>
                                <div class="pl-2">{{MBS.actions.TEXT_UP11(SELECTED_ITEM.name)}}</div> 
                            </v-layout>
                            <v-layout >
                                <strong>{{MBS.actions.TEXT_UP('Selected item Code: ')}}</strong>
                                <div class="pl-2">{{SELECTED_ITEM.code}}</div> 
                            </v-layout> 
                        </v-card>
                        <form id="itemDetailForm" @submit.prevent="SUBMIT_ITEM(CHILD)">
                            <v-layout row wrap class="pa-1">   
                                <!-- FIELD --> 
                                <template v-for="(item, index) in CHILD?FIELD_CHILD_ITEMS:FIELD_ITEMS" >
                                    <v-col cols="12" :sm="item.sm" :md="item.md" :key="index">
                                        <v-text-field v-if="item.value=='code'" 
                                            :required ="item.required?true:false"  
                                            filled=""
                                            :type="item.type" autocomplete="one-time-code"  
                                            v-model="inputCode" 
                                            :label="item.label" />  
                                        <v-textarea  v-else-if="item.type=='textarea'"  
                                            :required ="item.required?true:false"  
                                            filled=""
                                            :type="item.type" autocomplete="one-time-code"  
                                            v-model="input[item.value]" 
                                            :label="item.label"/>  
                                        <v-select v-else-if="item.type=='select'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="SELECT_ITEMS[item.select.name]"
                                            :item-value="item.select.value"
                                            :item-text="item.select.text"
                                            :label="item.label" />
                                        <v-select v-else-if="item.type=='select_gender'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :required ="item.required?true:false"
                                            :items="['Male','Female']" 
                                            :label="item.label" />
                                        <v-select v-else-if="item.type=='select_marital_status'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="['Single','Married']" 
                                            :label="item.label" />
                                        <v-autocomplete v-else-if="item.type=='autocomplete'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="SELECT_ITEMS[item.select.name]"
                                            :item-value="item.select.value"
                                            :item-text="item.select.text"
                                            :label="item.label" />
                                        <v-text-field v-else
                                            :required ="item.required?true:false"
                                            filled=""
                                            :type="item.type" autocomplete="one-time-code"  
                                            v-model="input[item.value]" 
                                            :label="item.label"/>  
                                    </v-col>   
                                </template>     
                            </v-layout>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn color="primary" text="" @click="dialog_add_item=false">CANCEL</v-btn>
                                <v-btn color="primary" :loading="input.loading" dark="" type="submit">Save</v-btn>
                            </v-card-actions>
                        </form> 
                    </v-container>
                    <small>Click Excel button to add {{MBS.actions.TEXT_UP0(CHILD?NAME_CHILD_ITEMS:NAME_ITEMS)}} using excel template</small>
                </v-card-text> 
            </v-card>
        </v-dialog>

        <!-- edit -->
        <v-dialog v-model="dialog_edit_item" persistent max-width="800px"> 
            <v-card>
                <v-card-title class="primary white--text" dark>
                    <span class="headline">EDIT {{MBS.actions.TEXT_UP(CHILD?NAME_CHILD_ITEM:NAME_ITEM)}}</span>
                    <v-spacer></v-spacer> 
                    <v-btn @click="dialog_edit_item=false" icon dark><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text> 
                    <v-container grid-list-lg>
                        <br>
                        <form id="itemDetailForm" @submit.prevent="SUBMIT_EDIT_ITEM(CHILD)">
                            <v-layout row wrap class="pa-1">   
                                <!-- FIELD -->  
                                <template v-for="(item, index) in CHILD?FIELD_CHILD_ITEMS:FIELD_ITEMS" >
                                    <v-col cols="12"  :sm="item.sm" :md="item.md" :key="index"> 
                                        <v-text-field 
                                        v-if="MBS.actions.TEXT_UP(item.value)===MBS.actions.TEXT_UP('code')" 
                                            :required ="item.required?true:false"  
                                            filled=""
                                            :disabled ="true"
                                            :type="item.type"
                                            v-model="inputCode"
                                            :value="inputCode" 
                                            :label="item.label" />  
                                        <v-textarea  v-else-if="item.type=='textarea'"   
                                            :required ="item.required?true:false"  
                                            filled=""
                                            :type="item.type" autocomplete="one-time-code"  
                                            v-model="input[item.value]" 
                                            :label="item.label"/>  
                                        <v-select v-else-if="item.type=='select'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="SELECT_ITEMS[item.select.name]"
                                            :item-value="item.select.value"
                                            :item-text="item.select.text"
                                            :label="item.label" />
                                         <v-select v-else-if="item.type=='select_gender'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="['Male','Female']" 
                                            :label="item.label" />
                                        <v-select v-else-if="item.type=='select_marital_status'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="['Single','Married']" 
                                            :label="item.label" />
                                        <v-autocomplete v-else-if="item.type=='autocomplete'" 
                                            v-model="input[item.value]" 
                                            filled
                                            :items="SELECT_ITEMS[item.select.name]"
                                            :item-value="item.select.value"
                                            :item-text="item.select.text"
                                            :label="item.label" />
                                        <v-text-field v-else
                                            :required ="item.required?true:false"
                                            filled=""
                                            :type="item.type" autocomplete="one-time-code"  
                                            v-model="input[item.value]" 
                                            :label="item.label"/>  
                                    </v-col>   
                                </template> 
                            </v-layout>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text="" @click="dialog_edit_item=false">CANCEL</v-btn>
                            <v-btn color="primary" :loading="input.loading" dark="" type="submit">Save</v-btn>
                            </v-card-actions>
                        </form> 
                    </v-container>
                    <small>..</small>
                </v-card-text> 
            </v-card>
        </v-dialog>  

        <!-- CHILD ITEMS DIALOG -->
        <v-dialog fullscreen v-model="dialog_child_items"   persistent max-width="900px"> 
            <v-card :style="'overflow: scroll; '" tile height="100%" color="b2">
                <v-layout column fill-height>
                    <template v-if="SELECTED_ITEM">
                        <v-card-title class="primary white--text" dark>
                            <v-btn  @click="dialog_child_items=false" color="white" icon="" class="ml-2 mr-5"><v-icon size="" >mdi-arrow-left</v-icon></v-btn>
                            <span class="headline">{{SELECTED_ITEM?SELECTED_ITEM.code:''}} : {{MBS.actions.TEXT_UP(SELECTED_ITEM?SELECTED_ITEM.name:'')}} : {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} </span>
                            <v-spacer></v-spacer>   
                            <v-btn  @click="dialog_child_items=false" color="white" icon="" class="ml-2"><v-icon size="" class="pr-1">mdi-close</v-icon></v-btn> 
                        </v-card-title>  
                        <v-card-text >   
                            <v-container grid-list-lg>
                                <br>
                                <v-card  > 
                                    <v-card-title>
                                        ACTION {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} 
                                        <v-spacer></v-spacer> 
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <div v-if="ITEM_ACTION_PERMISSIONS">
                                        <v-data-table
                                            :headers="HEADERS('action')"
                                            :items="ITEM_ACTION_PERMISSIONS"
                                            :search="search" >

                                            <template v-slot:top> 
                                            </template>  
                                            <template v-slot:item.read_permission="{item}" >  
                                                <div class="pr-5">
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'read_permission',true)" 
                                                        icon="" v-if="item.my_permission?item.my_permission.read_permission:false">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'read_permission',false)" 
                                                        v-else icon="">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                    </v-btn> 
                                                </div> 
                                            </template>
                                            <template v-slot:item.write_permission="{item}" >  
                                                <div class="pr-5">
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'write_permission',true)" 
                                                        icon="" v-if="item.my_permission?item.my_permission.write_permission:false">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'write_permission',false)" 
                                                        v-else icon="">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                    </v-btn> 
                                                </div> 
                                            </template>
                                            <template v-slot:item.delete_permission="{item}" > 
                                                <div class="pr-5">
                                                    <v-btn icon="" @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'delete_permission',true)" 
                                                        v-if="item.my_permission?item.my_permission.delete_permission:false">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'delete_permission',false)" 
                                                        v-else icon="">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                    </v-btn> 
                                                </div> 
                                            </template>
                                            <template v-slot:no-data>
                                                <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                                    <v-icon>warning</v-icon>
                                                    Empty
                                                </v-layout>
                                            </template>
                                        </v-data-table>    
                                    </div>
                                    <v-layout v-else justify-center align-center class="py-15">
                                       <v-icon>warning</v-icon> 
                                       <div class="pl-2 pt-1">No data found</div>
                                    </v-layout>
                                </v-card> 
                                <br>
                                <v-card   > 
                                    <v-card-title>
                                        PAGE {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} 
                                        <v-spacer></v-spacer> 
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <div v-if="ITEM_PAGE_PERMISSIONS">
                                        <v-data-table
                                            :headers="HEADERS('page')"
                                            :items="ITEM_PAGE_PERMISSIONS"
                                            :search="search" >

                                            <template v-slot:top> 
                                            </template>  
                                            <template v-slot:item.view_permission="{item}" >  
                                                <div class="pr-5">
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'view_permission',true)" 
                                                        icon="" v-if="item.my_permission?item.my_permission.view_permission:false">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'view_permission',false)"  
                                                        v-else icon="">
                                                        <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                    </v-btn> 
                                                </div> 
                                            </template>
                                            <template v-slot:no-data>
                                                <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                                    <v-icon>warning</v-icon>
                                                    Empty
                                                </v-layout>
                                            </template>
                                        </v-data-table>  
                                    </div>
                                    <v-layout v-else justify-center align-center class="py-15">
                                       <v-icon>warning</v-icon> 
                                       <div class="pl-2 pt-1">No data found</div>
                                    </v-layout>
                                </v-card> 
                            </v-container>  
                        </v-card-text>    
                    </template>
                    <template v-else>
                        <v-card>
                            <v-layout justify-center align-center class="pa-5">
                                No selected item
                            </v-layout>
                        </v-card>
                    </template>
                    <v-spacer></v-spacer>
                    <div >
                        <v-divider></v-divider>
                        <v-layout justify-center="" class="px-5 py-5">  
                            <v-spacer></v-spacer>
                            <v-btn outlined @click="dialog_child_items=false" color="primary" >CLOSE</v-btn> 
                        </v-layout>   
                    </div> 
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
  </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.USER_GROUPS.values
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                CHILD:false,
                SELECTED_ITEM:null,
                NAME_ITEMS:DATA.ITEMS.USER_GROUPS.names,
                NAME_ITEM:DATA.ITEMS.USER_GROUPS.name,
                VALUE_ITEMS:DATA.ITEMS.USER_GROUPS.values,
                VALUE_ITEM:DATA.ITEMS.USER_GROUPS.value,

                NAME_CHILD_ITEMS:DATA.ITEMS.PERMISSIONS.names,
                NAME_CHILD_ITEM:DATA.ITEMS.PERMISSIONS.name,
                VALUE_CHILD_ITEMS:DATA.ITEMS.PERMISSIONS.values,
                VALUE_CHILD_ITEM:DATA.ITEMS.PERMISSIONS.value,

                menuTableSet:false, 
                menuTableSetChild:false,
                dialog_add_item:false,
                dialog_edit_item:false, 
                dialog_child_items:false,
                inputCode:null,
                input:{},
                search:'',  
                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},  
                ],
                FIELD_CHILD_ITEMS:[
                    {type:"select",name:"permission",value:"permission_code",label:"Select Permission",required:true,sm:12,md:12,select:{
                        name:'permissions',
                        value:'code',
                        text:'name'
                    }}, 
                ],
                HEADER_ITEMS:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},   
                    {id:0,name:"code",value:'code',show:true},   

                    {id:0,name:"create",value:'created_at',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},  
                    {id:0,name:"action",align:"right",show:true}, 
                ],
                HEADER_CHILD_ITEMS:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},   
                    {id:0,name:"code",value:'code',show:true},   

                    {id:0,name:"create",value:'created_at',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},  
                    {id:0,name:"action",align:"right",show:true}, 
                ],
                HEADER_PAGE_PERMISSION:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},   
                    {id:0,name:"access",value:'access_type',show:true},   
                    {id:0,name:"view",value:"view_permission",align:"right",show:true},        
                ],    
                HEADER_ACTION_PERMISSION:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},    
                    {id:0,name:"access",value:'access_type',show:true},    
                    {id:0,name:"read",value:"read_permission",align:"right",show:true},    
                    {id:0,name:"write",value:"write_permission",align:"right",show:true},    
                    {id:0,name:"delete",value:"delete_permission",align:"right",show:true},      
                ], 
                items : [
                    {
                        ion:"statement :",
                        src:'/smart_realtors/imgs/header3.jpg',
                        title:"MANAGE", 
                    }, 
                ],    
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:this.items,
                    color:'black',
                    height:"250",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            ACCESS_ITEM(){
                return permission=>{
                    return  true
                    if (permission === "read") {
                        return this.MBS.actions.ACCESS_READ(this.VALUE_ITEMS) 
                    }else if (permission === "write") {
                        return this.MBS.actions.ACCESS_WRITE(this.VALUE_ITEMS) 
                    }else if (permission === "edit") {
                        return this.MBS.actions.ACCESS_EDIT(this.VALUE_ITEMS) 
                    }else if (permission === "delete") {
                        return this.MBS.actions.ACCESS_DELETE(this.VALUE_ITEMS) 
                    }else{
                        return false
                    }
                }
            },
            
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },
            PageTaps(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("security",2,true) 
                return thisLinks
            },
             
            PATH_ITEM(){ 
                let link = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_user_groups,true) 
                return link
            }, 
            DATA_ITEMS(){ 
                return this.$store.getters.getCompanyUserGroups
            }, 
            DATA_CHILD_ITEMS(){
                return this.$store.getters.getPermissions
            },   
            SELECT_ITEMS(){ 
                let item = this.DATA_CHILD_ITEMS 
                return {
                    permission:item
                }
            }, 
            JOINED_DATA_ITEMS(){
                let DATA_ITEMS = this.DATA_ITEMS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_ITEMS
                }) 
                return join
            },
            JOINED_CHILD_DATA_ITEMS(){
                let DATA_ITEMS = this.DATA_CHILD_ITEMS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:'permissions'
                }) 
                return join
            },
            SELECTED_DATA_ITEM(){
                let item = this.DATA_ITEMS
                let SELECTED_ITEM = this.SELECTED_ITEM 
                if(!item){return null}
                if(!SELECTED_ITEM){return null}

                let filtered_item = item.find(child=>{
                    return child.code == SELECTED_ITEM.code
                }) 
                return filtered_item
            }, 
            ITEM_PERMISSIONS(){
                // let item = this.SELECTED_ITEM
                let item = this.SELECTED_DATA_ITEM
                if(!item){return null}
                let object_permissions = item.permissions
                let permissions = this.MBS.actions.OBJECT_ITEMS(object_permissions)
                return permissions
            },
            JOINED_ITEM_PERMISSIONS(){
                let DATA_ITEMS = this.ITEM_PERMISSIONS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_CHILD_ITEMS
                }) 
                if(!join){return null}
                let filtered = join.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.access_type) == this.MBS.actions.TEXT_UP('company')
                })  
                return filtered
            },
            ITEM_PAGE_PERMISSIONS(){
                let DATA_ITEMS = this.JOINED_ITEM_PERMISSIONS 
                if(!DATA_ITEMS){return null}
                let filtered = DATA_ITEMS.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.permission_type) == this.MBS.actions.TEXT_UP('page')
                })  
                return filtered
            },
            ITEM_ACTION_PERMISSIONS(){
                let DATA_ITEMS = this.JOINED_ITEM_PERMISSIONS
                if(!DATA_ITEMS){return null}
                let filtered = DATA_ITEMS.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.permission_type) == this.MBS.actions.TEXT_UP('action')
                })  
                return filtered
            }, 
        },
        methods: { 
            HEADERS(type){
                try {
                    let hds = null
                    if (type === this.VALUE_ITEMS) {
                        hds = this.HEADER_ITEMS
                    }else if (type === 'excel'){
                        hds = this.HEADER_ITEMS2
                    } else if (type === this.VALUE_CHILD_ITEMS){
                        hds = this.HEADER_CHILD_ITEMS
                    } else if (type === 'page'){
                        hds = this.HEADER_PAGE_PERMISSION
                    } else if (type === 'action'){
                        hds = this.HEADER_ACTION_PERMISSION
                    } 
                    let headers = this.MBS.actions.CREATE_TABLE_HEADER(hds) 
                    return headers
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'HEADERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            ADD_ITEM(child){
                try {  
                    // IF NOT CHILD DESELECT
                    if (!child) {
                        this.SELECTED_ITEM = null
                    }
                    let p = this.ACCESS_ITEM("add")
                    if (!p) {
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:this.MBS.text.add_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).title,
                           text:this.MBS.text.add_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).text,
                           btnYes:this.MBS.text.add_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).btnYes 
                       }) 
                    } else { 
                        this.inputCode = null
                        this.input = {} 
                        this.CHILD = child?true:false
                        this.dialog_add_item = true 
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            SUBMIT_ITEM(child){
                try {
                    //convert code to int
                    let code = this.inputCode*1 
                    let array = child?this.DATA_CHILD_ITEMS:this.DATA_ITEMS

                    //check code
                    if ((child?this.FIELD_CHILD_ITEMS[0].value:this.FIELD_ITEMS[0].value)==='code') {
                        if (code === 0) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.check_code_error_0.title,
                                text:this.MBS.text.check_code_error_0.text,
                                btnYes:this.MBS.text.check_code_error_0.btnYes,
                                btnNo:this.MBS.text.check_code_error_0.btnNo,
                                action:{
                                    code:PAGE_NAME+"=AUTO-SET-CODE",
                                    data:array
                                }
                            }) 
                            return
                        }
                        if (this.MBS.actions.CODE_CHECK(array,code)) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.check_code_error_exist.title,
                                text:this.MBS.text.check_code_error_exist.text,
                                btnYes:this.MBS.text.check_code_error_exist.btnYes,
                                btnNo:this.MBS.text.check_code_error_exist.btnNo,
                                action:{
                                    code:PAGE_NAME+"=AUTO-SET-CODE",
                                    data:array
                                }
                            })  
                            return
                        } 
                    }

                    //CREATE ITEM ARRAY
                    let item_array = {}
                    let required = false
                    let field_items = child?this.FIELD_CHILD_ITEMS:this.FIELD_ITEMS
                    field_items.forEach(field => {
                        if (field.value==='code') {
                            item_array.code = code 
                        } else {
                            let value = this.input[field.value]?this.input[field.value]:null
                            if (!value) {
                                if (field.required) {
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:false,
                                        title:this.MBS.text.field_required(field.label).title,
                                        text:this.MBS.text.field_required(field.label).text,
                                        btnYes:this.MBS.text.field_required(field.label).btnYes
                                    })
                                    required = true
                                }
                            }
                            item_array[field.value] = value
                        } 
                    });
                    if (required) {return}
                    if (child) {
                        if (!this.SELECTED_ITEM) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.error_0.title,
                                text:this.MBS.text.error_0.text,
                                btnYes:this.MBS.text.error_0.btnYes, 
                            }) 
                            return
                        }
                        item_array[this.MBS.actions.TEXT_UP0(this.VALUE_ITEM)+"_code"] = this.SELECTED_ITEM.code 
                    }

                     
                    // 
                    this.input.code = code 
                    this.input.check = true
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.add_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                        text:this.MBS.text.add_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                        btnYes:this.MBS.text.add_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                        btnNo:this.MBS.text.add_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnNo,
                        action:{
                            code:PAGE_NAME+"=ADD-ITEM",
                            child:child?true:false,
                            data:{
                                ...item_array
                            }
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            EDIT_ITEM(item,child){
                try {
                    let p = this.ACCESS_ITEM("edit")
                    if (!p) {
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:this.MBS.text.edit_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).title,
                           text:this.MBS.text.edit_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).text,
                           btnYes:this.MBS.text.edit_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).btnYes 
                       }) 
                    } else { 
                        this.input = {}
                        let field_items = child?this.FIELD_CHILD_ITEMS:this.FIELD_ITEMS
                        field_items.forEach(field => {
                            if (field.value==='code') { 
                                this.inputCode = item.code  
                            } else {
                                this.input[field.value] = item[field.value] 
                            }
                        }); 
                        this.input.key = item.key 
                        this.CHILD = child?true:false
                        this.dialog_edit_item = true
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            SUBMIT_EDIT_ITEM(child){
                try { 
                    this.CHILD = false
                    this.input.check = true
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.edit_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                        text:this.MBS.text.edit_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                        btnYes:this.MBS.text.edit_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                        btnNo:this.MBS.text.edit_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnNo,
                        action:{
                            code:PAGE_NAME+"=EDIT-ITEM",
                            child:child?true:false,
                            data:this.input
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            ADD_ITEM_PERMISSION(item,permission,permission_type){
                try { 
                    let user_group_key = item?item.key:null
                    let permission_code = item?permission.code:null
                    let permission_key = item?permission.key:null
                    let my_permission = item?permission.my_permission:null
                    let my_permission_key = my_permission?my_permission.key:null
                    if (!user_group_key) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }else if (!permission_code) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }else if (!permission_type) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    } else { 
                        let view_permission = my_permission?my_permission.view_permission :false
                        let read_permission  = my_permission?my_permission.read_permission :false
                        let write_permission  = my_permission?my_permission.write_permission :false
                        let delete_permission  = my_permission?my_permission.delete_permission :false
                        let data = {}
                        if (permission_type=='view_permission') {
                            data = {view_permission:!view_permission}
                        }if (permission_type=='read_permission') {
                            data = {read_permission:!read_permission}
                        }if (permission_type=='write_permission') {
                            data = {write_permission:!write_permission}
                        }if (permission_type=='delete_permission') {
                            data = {delete_permission:!delete_permission}
                        }  
                        let  ACTION_TYPE = null
                        if (my_permission_key) {
                            ACTION_TYPE= "=UPDATE-PERMISSION"
                        } else {
                            ACTION_TYPE= "=ADD-PERMISSION"
                        }  
                        this.input.check = true
                        let mbs_text = this.MBS.text.update_item("Permission")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+ACTION_TYPE, 
                                data:{
                                    ...data,
                                    user_group_key:user_group_key,
                                    permission_code:permission_code,
                                    my_permission_key:my_permission_key
                                }
                            }
                        })  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM_PERMISSION',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            SHOW_CHILD_ITEM(item){
                try {  
                    this.SELECTED_ITEM = item 
                    this.dialog_child_items = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHOW_CHILD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_ITEM(item,child){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                            text:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                       }) 
                    } else {
                        this.CHILD=child?true:false,
                        this.input.check = true 
                        if (item.image) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                                text:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                                btnNo:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnNo,
                                action:{
                                    code:PAGE_NAME+"=DELETE-IMAGE",
                                    child:child?true:false,
                                    data:item,
                                    image:item.image,
                                    key:item.key
                                }
                            })  
                        } else {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                                btnNo:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnNo,
                                action:{
                                    code:PAGE_NAME+"=CONFIRM-DELETE-ITEM",
                                    child:child?true:false,
                                    data:item
                                }
                            })  
                        }
                    }     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ITEM',
                        page:PAGE_NAME, 
                    })  
                }
            }, 
            DELETE_ALL_ITEMS(){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(this.NAME_ITEMS).title,
                            text:this.MBS.text.delete_item_permission_denied(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(this.NAME_ITEMS).btnYes,
                       }) 
                    } else {
                        this.input.check = true
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_all(this.NAME_ITEMS).title,
                            text:this.MBS.text.delete_item_all(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.delete_item_all(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.delete_item_all(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=CONFIRM-DELETE-ALL-ITEMS", 
                            }
                        })  
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ALL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            setAutoCode(){ 
                try {
                    let code = this.MBS.actions.CODE_GET_LAST(this.CHILD?this.DATA_CHILD_ITEMS:this.DATA_ITEMS) 
                    this.inputCode= (code+1 )
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.generate_code_successful.title,
                        text:this.MBS.text.generate_code_successful.text,
                        btnYes:this.MBS.text.generate_code_successful.btnYes,
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'setAutoCode',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            isAutoCode(number){ 
                try {
                    let code = this.MBS.actions.CODE_GET_LAST(this.CHILD?this.DATA_CHILD_ITEMS:this.DATA_ITEMS)
                    if ((code+1 )=== number) {
                        return true
                    } 
                } catch (error) {
                   this.MBS.actions.error({
                        error:error,
                        from:'isAutoCode',
                        page:PAGE_NAME, 
                    })  
                }
                return false
            },
 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                }   
                            } else { 
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=EDIT-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.loading = true  
                                        this.$store.dispatch("fi_update",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/",
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else {
                                    
                                }
                            } 
                        }  
                        if (action.code ===PAGE_NAME+'=ADD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_add_item = false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=ADD-CHILD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_add_child_item = false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=EDIT-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_edit_item = false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=EDIT-CHILD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_edit_child_item= false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=ADD-PERMISSION') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false  
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_CHILD_ITEM,
                                            path:this.PATH_ITEM+action.data.user_group_key+"/permissions/",
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                }   
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=UPDATE-PERMISSION') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.loading = true  
                                        this.$store.dispatch("fi_update",{
                                            action:this.VALUE_CHILD_ITEM,
                                            path:this.PATH_ITEM+action.data.user_group_key+"/permissions/"+action.data.my_permission_key,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else {
                                    
                                }
                            } 
                        }   

                        if (action.code ===PAGE_NAME+'=CONFIRM-DELETE-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting this "+action.child?this.NAME_CHILD_ITEM:this.NAME_ITEM,btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-ITEM",
                                            }
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else {
                                    
                                }
                            }  
                        }  
                        if (action.code ===PAGE_NAME+"=CONFIRM-DELETE-ALL-ITEMS") {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting all "+this.MBS.actions.TEXT_UP0(this.NAME_ITEMS)+". This action can not be reversed",btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-ALL-ITEMS",
                                            }
                                        })   
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else { 
                                }
                            }   
                        } 
                        
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = false
                                        let password = this.MBS.crypt.encrypt(action.password) 
                                        //TODO..............
                                        if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                        this.$store.dispatch("fi_delete",{
                                                action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                                path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/"
                                            })  
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        }
                        if (action.code ===PAGE_NAME+'=DELETE-CHILD-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = false
                                        let password = this.MBS.crypt.encrypt(action.password)
                                        //TODO..............
                                        if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                        this.$store.dispatch("fi_delete",{
                                                action:this.VALUE_CHILD_ITEM,
                                                path:this.PATH_CHILD_ITEM+action.data.key+"/"
                                            })  
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEMS') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.check = false
                                        let password = this.MBS.crypt.encrypt(action.password)
                                        //TODO..............
                                        if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                            this.$store.dispatch("fi_delete",{
                                                action:this.VALUE_ITEMS,
                                                path:this.PATH_ITEM 
                                            })
                                        }  
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        } 
                        if (action.code===PAGE_NAME+'=DELETE-IMAGE') { 
                            if (action.YES) {
                                if (this.input.check) {
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.$store.dispatch("fi_deleteImage",{
                                            path:this.PATH_ITEM+action.key,
                                            link:action.image.link,
                                            action:this.VALUE_ITEM_IMAGE,
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } 
                            } else { 
                            }
                        }

                        if (action.code ===PAGE_NAME+'=SELECT-EXCEL') {
                            if (action.YES) {
                                this.SELECT_EXCEL()   
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=REMOVE-EXCEL-ITEM') {
                            if (action.YES) {
                                let item =action.item 
                                let items = this.EXCEL_DATA_ITEMS
                                this.EXCEL_DATA_ITEMS = items.filter(cs=>{
                                    return cs.code != item.code
                                })  
                            } else { 
                            }
                        } 

                        if (action.code ===PAGE_NAME+'=UPLOAD-REPLACE-EXCEL') {
                            if (this.input.check) {  
                                this.input.check = false  
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_set",{
                                            action:this.VALUE_ITEMS,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us,
                                            array:true
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }   
                        }

                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCode = (this.MBS.actions.CODE_GET_LAST(action.data)+1)
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        }
                        if (action.code===PAGE_NAME+":DELETE-MESSAGE") {
                            if (action.YES) {
                                this.$store.dispatch("fi_delete",{
                                    action:"Message",
                                    path:"MBS/MESSAGES/"+action.key
                                })  
                            } else { 
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                            let addingItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_ITEM
                            })
                            let addingChildItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_CHILD_ITEM
                            })
                            let updatingItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_ITEM
                            })  
                            let updatingChildItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_CHILD_ITEM
                            })  
                            let deletingItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEM
                            })
                            let deletingChildItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_CHILD_ITEM
                            })
                            let deletingAllItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEMS
                            })
                            let deletingAllChildItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_CHILD_ITEMS
                            })
                             

                            if (gettingItems) {
                                this.PAGE_LOAD =false
                            }else{
                                this.PAGE_LOAD =true
                            }

                           

                            if (addingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  
                            if (addingChildItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (updatingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  
                            if (updatingChildItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (deletingItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  
                            if (deletingChildItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  

                            if (deletingAllItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }
                            if (deletingAllChildItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }

                             
                        }else{
                            this.PAGE_LOAD =true
                        }

                        //ADD ITEM RESPONSE
                        if (res.res==='adding/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.add_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.add_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_add_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                            
                        }else if (res.res==='adding/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.add_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.add_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }
                        if (res.res==='adding/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_add_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                            
                        }else if (res.res==='adding/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }

                        if (res.res==='updating/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='updating/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                        
                        if (res.res==='deleting/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='deleting/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                        if (res.res==='deleting/'+this.VALUE_ITEMS+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEMS).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEMS).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_ITEMS+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEMS).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEMS).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 


                        if (res.res==='deleting/image/'+this.VALUE_ITEM_IMAGE+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/image/'+this.VALUE_ITEM_IMAGE+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }


                        if (res.res==='setting/'+this.VALUE_ITEMS+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEMS).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEMS).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()  
                            this.dialog_selected_item = false
                        }else if (res.res==='setting/'+this.VALUE_ITEMS+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEMS).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEMS).btnYes, 
                            })  
                            this.input.loading = false
                            this.dialog_selected_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }

                         

                        
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
